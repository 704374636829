.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .content {
    position: relative;
    text-align: center;
  }
  
  .content img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
  }
  
  .content div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  