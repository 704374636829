.form-container {
  margin: 40px auto;
  max-width: 600px;
  padding: 20px 20px;
}

.image-container {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.captured-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.camera-icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #d9d9d9;
  font-size: 64px;
  color: #1677ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_Image
{
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}
.list {
  background-color: #f2f2f2;
  padding: 8px;
  list-style-type: none;
}

.formItem {
  margin-bottom: 24px;
}

.responsive-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries for Responsive Design */
@media (max-width: 767px) {
  .form-container {
    padding: 10px;
  }

  .image-container {
    margin-bottom: 12px;
  }

  .captured-image {
    width: 150px;
    height: 150px;
  }

  .camera-icon {
    width: 150px;
    height: 150px;
    font-size: 48px;
  }
}
