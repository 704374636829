.userList {
    flex: 4;
  }
  
  .userListUser {
    display: flex;
    align-items: center;
  }
  .input-container {
    margin-right: 20px;
  }
  .button-container {
    display: flex;
  }
  .input-container {
    margin-right: 20px;
  }
  
  .input-text {
    width: 200px;
  }
  .userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 25px;
  }
  
  .userListAction {
    display: flex;
    justify-content :space-evenly;
  }
  
  .userListEdit {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
  }
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .userListDelete {
    color: red;
    cursor: pointer;
  }
  
  .input-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-right: 20px;
  }
  
  input#search {
    padding: 8px 5px;
    padding-right: 24px;
  }
  .actionButtonsContainer {
    display: flex;
    align-items: center;
    gap: 8px; /* Add some spacing between buttons */
  }
  #clear-search {
    display: none;
    position: absolute;
    right: 3px;
    top: 2px;
    bottom: 1px;
    padding: 0px 5px;
    font-size: 25px;
    user-select: none;
    cursor: pointer;
    color: #9e9e9e;
    z-index: 12;
    height: 33px;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }
  